<template>
	<div class="detail_page">
		<div class="top_holder"></div>
		<!-- 面包屑 -->
		<div class="breadcrumb web-center" style="margin: 40px auto 20px">
			<a-breadcrumb>
				<a-breadcrumb-item><a href="#/extension">插件中心</a></a-breadcrumb-item>
				<a-breadcrumb-item><a href="javascript:;">内容详情</a></a-breadcrumb-item>
			</a-breadcrumb>
		</div>
		<!-- 内容主体 -->
		<div class="detail_page_content web-center">
			<!-- 左侧文章主体 -->
			<div class="detail_page_left">
				<div class="extension_info">
					<img :src="detailData.icon_url" alt="" class="extension_icon" />
					<div class="extension_right">
						<div class="extension_right_name">{{ detailData.title }}</div>
						<div class="extension_right_desc">
							{{ detailData.subtitle }}
						</div>
					</div>
				</div>
				<div class="extension_detail_content">
					<!-- 轮播 -->
					<div class="swiper">
						<div class="swiper-wrapper">
							<div class="swiper-slide" v-for="item in detailData.detail_img" :key="item">
								<img :src="item" alt="image" style="width: 100%; height: 100%" />
							</div>
							<!-- 
              <div class="swiper-slide">
                <img
                  :src="'https://img0.baidu.com/it/u=183085330,2221128922&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1663693200&t=fed1c14c4c7a2f108913c23644b8f9a1'"
                  alt="image"
                  style="width: 100%; height: 100%"
                />
              </div>

              <div class="swiper-slide">
                <img
                  :src="'https://img2.baidu.com/it/u=918728721,1627056545&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1663693200&t=56f36d62ca03751119fb63a345932ad7'"
                  alt="image"
                  style="width: 100%; height: 100%"
                />
              </div> -->
						</div>

						<!-- 如果需要分页器 -->

						<div class="swiper-pagination"></div>
					</div>
					<!-- 插件文字描述 -->
					<div class="description" v-html="detailData.description"></div>
				</div>
			</div>
			<!-- 右侧 -->
			<div class="detail_page_right">
				<!-- 右侧配置信息 -->
				<div class="extension_config">
					<div class="add_btn" @click="addExtension">
						{{ detailData.is_install ? "分配环境" : "立即添加" }}
					</div>
					<ul class="config_info">
						<li class="config_info_item">
							<div class="config_info_item_name">
								<img src="@/assets/img/extension/extension_config_version.png" alt="" class="config_info_item_icon" />版本号
							</div>
							<div class="config_info_item_desc">{{ detailData.version }}</div>
						</li>
						<!-- <li class="config_info_item">
              <div class="config_info_item_name">
                <img
                  src="@/assets/img/extension/extension_config_category.png"
                  alt=""
                  class="config_info_item_icon"
                />所属分类
              </div>
              <div class="config_info_item_desc">2.0.9(chrome)</div>
            </li> -->
						<li class="config_info_item">
							<div class="config_info_item_name"><a-icon type="user" style="font-size: 20px; color: #878fa7" />来源</div>
							<div class="config_info_item_desc">
								{{ source[detailData.source] }}
							</div>
						</li>
						<li class="config_info_item">
							<div class="config_info_item_name"><a-icon type="download" style="font-size: 20px; color: #878fa7" />安装量</div>
							<div class="config_info_item_desc">
								{{ detailData.install_total }}
							</div>
						</li>
						<!-- <li class="config_info_item">
              <div class="config_info_item_name">
                <img
                  src="@/assets/img/extension/extension_config_core.png"
                  alt=""
                  class="config_info_item_icon"
                />内核
              </div>
              <div class="config_info_item_desc">2.0.9(chrome)</div>
            </li> -->
						<li class="config_info_item">
							<div class="config_info_item_name">
								<img src="@/assets/img/extension/extension_config_date.png" alt="" class="config_info_item_icon" />更新日期
							</div>
							<div class="config_info_item_desc" :title="detailData.update_time">
								{{ detailData.update_time }}
							</div>
						</li>
					</ul>
					<div class="disclaimer">插件拓展基于<a href="/#/extension" style="color: #4c84ff">免责声明</a></div>
				</div>
				<!-- 右侧推荐列表 -->
				<div class="recommend_list">
					<div class="recommend_list_title">热门推荐</div>
					<RankList :listData="listData" rankListKind="recommend"></RankList>
				</div>
			</div>
		</div>
		<!-- 添加插件弹窗 -->
		<AddExtension :visible="visible" :extensionItem="item" @close="visible = false" @install="install"></AddExtension>
	</div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { pluginInfo, pluginList } from "@/api/extension.js";
import RankList from "../components/RankList.vue";
import AddExtension from "../components/AddExtension.vue";
export default {
	components: {
		RankList,
		AddExtension,
	},
	data() {
		return {
			id: "",
			detailData: {},
			item: {},
			visible: false,
			source: {
				1: "快洋淘浏览器",
				2: "谷歌商店同步",
				3: "企业上传",
				4: "第三方上传",
			},
			listData: [],
		};
	},
	created() {
		this.id = this.$route.query.id;
		this.getDetail();
	},

	mounted() {},
	methods: {
		async getDetail() {
			const { data } = await pluginInfo({ id: this.id });
			this.detailData = data.data;
			this.$nextTick(() => {
				new Swiper(".swiper", {
					loop: true, // 循环模式选项

					autoplay: true,

					// 如果需要分页器

					pagination: {
						el: ".swiper-pagination",

						clickable: true,
					},
				});
			});
			//获取推荐列表
			this.getRecommend();
		},
		getRecommend() {
			const category = this.detailData.category;
			const index = Math.floor(Math.random() * category.length);
			const category_first_id = category[index];
			pluginList({
				category_first_id: category_first_id, //一级分类id
				// category_second_id: this.categorySecondId, //二级分类id
				pagesize: 10, //每页数据
				page: 1, //当前页码
				// sort: this.sort, //排序 最新发布 create_time 最热 install_total  默认create_time
				// show_install: this.onlyShowInstall, //是否展示已添加插件 1则只展示已安装插件，0为展示所有 默认0
			}).then(res => {
				this.listData = res.data.data.list;
			});
		},
		addExtension() {
			this.item = this.detailData;
			this.visible = true;
		},
		install() {
			this.item.is_install = true;
		},
	},
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.detail_page {
	.top_holder {
		height: 60px;
		background-color: #fff;
	}
	.detail_page_content {
		width: 1200px;
		.flex(@align:flex-start);
		.detail_page_left {
			width: 895px;
			min-height: 1172px;
			box-sizing: border-box;
			padding: 28px;
			background: #ffffff;
			border-radius: 4px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #878fa7;
			line-height: 20px;
			.extension_info {
				.flex(flex-start);
				.extension_icon {
					width: 68px;
					height: 68px;
					margin-right: 16px;
				}
				.extension_right {
					width: 750px;
					.extension_right_name {
						margin-bottom: 10px;
						font-size: 18px;
						font-family: PingFang-SC-Heavy, PingFang-SC;
						font-weight: 800;
						color: #2c354b;
					}
					.extension_right_desc {
						.ellipsis();
					}
				}
			}
			.swiper {
				width: 100%;
				height: 472px;
				margin-top: 40px;
				position: relative;
				overflow: hidden;
			}
			.description {
				margin-top: 20px;
				white-space: pre-line;
			}
		}
		.detail_page_right {
			width: 282px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #878fa7;
			line-height: 20px;
			.extension_config {
				padding: 20px 12px 0;
				background-color: #fff;
				.add_btn {
					width: 237px;
					height: 44px;
					margin: 0 auto 42px;
					background: #4c84ff;
					box-shadow: 0px 2px 5px 0px rgba(76, 132, 255, 0.31);
					border-radius: 4px;
					font-size: 16px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #ffffff;
					line-height: 44px;
					text-align: center;
					cursor: pointer;
					&:hover {
						background: #6f9cff;
					}
					&.active {
						background: #f4f4f4;
						box-shadow: none;
						color: #cccccc;
						border: 1px solid #dcdcdc;
					}
				}
				.config_info {
					border-bottom: 1px solid #e5eaef;
					.flex(@wrap:wrap);
					.config_info_item {
						width: 49%;
						height: 48px;
						margin-bottom: 26px;
						&:nth-child(2n + 1) {
							border-right: 1px solid #e5eaef;
						}
						.config_info_item_name {
							.flex(center);
							.config_info_item_icon {
								width: 20px;
								margin-right: 4px;
							}
						}
						.config_info_item_desc {
							width: 100%;
							margin-top: 12px;
							color: #374567;
							text-align: center;
							.ellipsis();
						}
					}
				}
				.disclaimer {
					height: 51px;
					line-height: 51px;
					text-align: center;
				}
			}
			.recommend_list {
				margin-top: 20px;
				background-color: #fff;
				.recommend_list_title {
					padding: 20px 24px;
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 600;
					color: #2c354b;
					line-height: 25px;
				}
			}
		}
	}
}
</style>
